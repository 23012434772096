@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';

.NPDProvider {
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-04;
    &-cards {
      width: 480px;
    }
    &-buttons {
      display: flex;
      gap: $spacing-05;
      align-items: center;
      align-self: flex-start;
    }
  }
  &__notifications {
    padding-bottom: $spacing-06;
    &-error.cds--inline-notification {
      width: 480px;
    }
    &-info.cds--inline-notification {
      max-width: fit-content;
    }
  }
  .NPDProvider__toast {
    width: auto;
    border-radius: $border-radius-01;
    position: fixed;
    left: 40px;
    bottom: 40px;
    animation: fade-in 1s;
    z-index: 8001;
  }
}
