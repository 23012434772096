@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';

.cds--data-table-container {
  .DataTable__CellDropdown {
    display: flex;
    align-items: center;
    gap: $spacing-03;

    > span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > svg,
    > button {
      flex-shrink: 0;
    }

    &--dirty {
      font-weight: $font-weight-06;
    }
  }
}
