@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';

.DataErrorNotification__toast {
  &.cds--toast-notification--warning {
    border-radius: $border-radius-01;
    animation: fade-in 1s;
    width: fit-content;
    align-items: center;
    border-left: 3px solid theme.get-var('main-button-danger-primary');
    background: #fff1f1;
    .cds--toast-notification__details {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .cds--actionable-notification__details {
      align-items: center;
    }
    .cds--toast-notification__title,
    .cds--toast-notification__subtitle {
      margin: 0;
      white-space: pre;
    }
    .cds--inline-notification__icon {
      margin-top: 0;
      fill: theme.get-var('main-button-danger-primary');
      path[opacity='0'] {
        fill: transparent;
      }
    }
    .cds--btn--secondary {
      color: theme.get-var('main-button-secondary');
      border-color: theme.get-var('main-button-secondary');
      margin-left: $spacing-05;
      &:hover {
        color: theme.get-var('main-button-secondary');
        border-color: theme.get-var('main-button-secondary');
        background-color: theme.get-var('background-hover');
      }
    }
    .cds--toast-notification__icon {
      margin-top: 0;
      fill: theme.get-var('main-button-danger-primary');
      path[opacity='0'] {
        fill: transparent;
      }
    }
  }
}
