@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.node-info-title {
  border-bottom: 1px solid theme.get-var('border-subtle');
  padding: $spacing-03 $spacing-05 $spacing-03 0;
  margin: $spacing-05 0;
}

.SidePanel__content {
  .assortment-group-name-input,
  .levels-radio-button-group {
    margin: $spacing-05 0;
    flex-grow: 0;
  }
}

.node-info-description {
  padding: $spacing-03 $spacing-05 $spacing-03 0;
  margin: $spacing-05 0;
}
.assortment-side-panel-title {
  @include body-01;
  margin: $spacing-05 0;
}

.assortment-side-panel-controls {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: $spacing-05 0;
  background: theme.get-var('background');
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $spacing-05;
}

.assortment-node-metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-03;
  margin: $spacing-03;
}

.cdt-legend {
  display: flex;
  align-items: center;
  min-height: 64px;
  padding-top: $spacing-05;
  gap: $spacing-05;
  border-bottom: 1px solid theme.get-var('border-subtle');
  :last-child {
    flex-grow: 1;
    text-align: right;
  }
  &-item {
    border-left: solid 14px;
    padding-left: $spacing-03;
    &--blue {
      border-color: theme.get-var('border-interactive-primary');
    }
    &--green {
      border-color: theme.get-var('tag-color-green');
    }
    &--orange {
      border-color: theme.get-var('border-interactive-secondary');
    }
  }
}

.cdt-controls {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .unsaved-changes {
    @include body-02;
    svg {
      margin-right: $spacing-02;
    }
    padding: 0 $spacing-05;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    color: theme.get-var('tag-color-blue');
  }
  .cds--inline-loading {
    padding-left: $spacing-05;
  }
}

.cds--btn.cds--btn--secondary.AssortmentProvider__edit-button {
  padding: $spacing-03;
}

.assortment-grid {
  user-select: none;
  .table-header {
    cursor: auto;
  }
  tr {
    cursor: pointer;
  }
}

.delist-input {
  margin: $spacing-05 0;
  position: relative;
}

.optimiser-radios {
  margin-bottom: $spacing-05;
}

.draggable-node-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-03;
  padding: $spacing-05;
  overflow-y: auto;
  max-height: 318px;

  .draggable-node {
    display: flex;
    gap: $spacing-03;
    user-select: none;
    padding: $spacing-05;
    border-radius: $spacing-03;
    background-color: theme.get-var('background');
    align-items: center;
    border: 1px solid theme.get-var('border-interactive-primary');

    &.dragging-true {
      background: theme.get-var('background-active');
      border-width: 2px;
    }
  }
}

#price-point-error-msg {
  position: absolute;
  top: $spacing-08;
}

.AssortmentLoader {
  margin-top: $spacing-05;
  background: theme.get-var('background');
  border-radius: $border-radius-01;
  box-shadow: $boxShadow-02;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  .cds--loading {
    margin-top: 1rem;
  }
  .cds--progress-bar {
    width: 400px;
    text-align: center;
    .cds--progress-bar__track {
      background-color: theme.get-var('layer-02');
    }
  }
}

.OptimiserGrid {
  .cds--table-toolbar {
    padding: $spacing-05;
    .cds--toolbar-content {
      align-items: center;
      gap: $spacing-03;
    }
  }
}

.HighlightToggle {
  display: flex;
  align-items: center;
  .cds--toggle {
    margin: 0 0 $spacing-05 $spacing-05;
  }
}
.HighlightControls {
  .cds--dropdown__wrapper {
    margin: 0;
    width: 275px;
  }

  .HighlightControls__legend-anchor {
    position: relative;

    .HighlightControls__legend {
      position: absolute;
      top: 32px;
      left: 16px;
      box-shadow: $boxShadow-02;
      border-radius: $border-radius-01;
      padding: $spacing-05;
      width: 18%;
      z-index: 1;
      background-color: theme.get-var('background');
      line-height: 20px;
      @include body-03;
      color: theme.get-var('text-secondary');

      .HighlightControls__legend-title {
        @include body-emphasis-03;
      }

      .HighlightControls__expand-btn {
        text-decoration: underline;
        color: theme.get-var('text-button-secondary');
        cursor: pointer;
        font-weight: $font-weight-04;
      }

      .HighlightControls__legend-item {
        display: flex;

        .HighlightControls__legend-item__label {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100px;
          flex-grow: 1;

          &.HighlightControls__legend-item__label--hidden {
            visibility: hidden;
          }
        }

        .HighlightControls__legend-item__info {
          @include body-emphasis-03;
        }

        .HighlightControls__legend-item__key {
          margin-left: $spacing-03;
          height: 12px;
          width: 24px;
          flex-shrink: 1;
          align-self: center;
        }
      }

      &.HighlightControls__legend--type-continuous,
      &.HighlightControls__legend--type-index {
        .HighlightControls__legend-item__key {
          height: initial;
          align-self: stretch;
          width: 65px;
        }
      }
    }
  }
}

.optimisation-controls {
  display: flex;
  gap: $spacing-05;
}

.OptimiserLayout {
  display: flex;
  flex-direction: column;
  gap: $spacing-05;
}

.OptimiserMode {
  box-shadow: $boxShadow-01;
  background-color: theme.get-var('background');
  border-radius: $border-radius-01;
  padding: $spacing-05;

  .cds--label {
    @include body-emphasis-02;
  }
}

.OptimiserButtonBar {
  display: flex;
  justify-content: space-between;

  > *:only-child {
    margin-left: auto;
  }
}
