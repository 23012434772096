@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/colors' as carbonColor;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.SidePanel__overlay {
  position: fixed;
  inset: 0;
  z-index: 3;
  transition: all 0.3s;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.SidePanel__container {
  position: fixed;
  top: 50px;
  bottom: 0;
  z-index: 4;
  background-color: theme.get-var('background');
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  right: 0;
  overflow: hidden;
  &.open {
    box-shadow: 0px 6px 16px #00000040;
  }
  .SidePanel__wrapper {
    overflow-y: scroll;
    padding: $spacing-05 $spacing-05 0 $spacing-07;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:has(.GenAIPanel) {
      overflow-y: hidden;
      padding-right: 0;

      .SidePanel__content {
        display: block;
        height: 100%;
      }
    }
    .SidePanel__draggable-edge {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 16px;
      cursor: ew-resize;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 40px;
        width: 6px;
        border-radius: 8px;
        background-color: theme.get-var('border-subtle');
      }

      &:hover {
        &:after {
          background-color: theme.get-var('border-interactive-primary');
        }
      }
    }
    .SidePanel__content {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .top-spacing {
      margin-top: 105px;
      &--title-only {
        margin-top: 65px;
      }
    }
    .SidePanel__close-button {
      align-self: flex-end;
      text-align: right;
      position: sticky;
      top: 0;
      z-index: 1;
      height: $spacing-06;
      background-color: theme.get-var('background');
    }
  }
  .SidePanel__title {
    margin-bottom: $spacing-03;
    @include body-02;
    cursor: default;
  }
  .SidePanelSection__content {
    margin-bottom: $spacing-05;
  }
  .SidePanelSection__divider {
    margin-bottom: $spacing-05;
    border-bottom: 1px solid theme.get-var('border-subtle-01');
    height: 1px;
  }
  .SidePanelActions__spacer {
    flex-grow: 1;
  }
  .SidePanelActions {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    padding: $spacing-05 0;
    background: theme.get-var('background');
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-top: 1px solid theme.get-var('border-subtle-01');
  }

  .AllUsers__actions-container {
    display: flex;
    gap: 62px;
    justify-content: space-between;
    margin-bottom: $spacing-06;
    .AllUsers__search {
      margin-bottom: 0;
      width: auto;
      flex-grow: 1;
    }
    .AllUsers__button-wrapper {
      width: 108px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.SidePanelTitle__section-title {
  border-radius: $border-radius-01;
  margin-bottom: $spacing-04;
  padding: $spacing-04;
  display: flex;
  align-items: center;
  gap: $spacing-02;
  @include heading-01;
  .SidePanel__title {
    @include heading-02;
  }
  background: carbonColor.$blue-10;
}

.SidePanelHeader {
  position: absolute;
  top: 0px;
  width: 100%;
  margin: 0 0 $spacing-05 0;
  &.section-title {
    margin-bottom: $spacing-04;
    padding: $spacing-03 0;
    border-bottom: 1px solid theme.get-var('border-subtle');
    .SidePanel__title {
      @include label-02;
    }
  }

  .SidePanelHeader__wrapper {
    position: relative;
    margin-right: 56px;
    z-index: 3;
    padding: $spacing-03 0;
    display: flex;
    flex-basis: 100%;

    &--hidden {
      @extend .SidePanelHeader__wrapper;
      justify-content: flex-end;
      background-color: transparent;
      margin-left: auto;
      width: 24px;
    }

    &--visible {
      @extend .SidePanelHeader__wrapper;
      justify-content: space-between;
      border-bottom: 1px solid theme.get-var('border-subtle-01');
      background-color: theme.get-var('background');
    }
  }

  .SidePanelHeader__title-wrapper {
    margin-top: $spacing-05;
  }

  .SidePanelHeader__title {
    @include heading-03;
    margin-bottom: $spacing-05;
    cursor: default;
  }
}

.form-label {
  @include body-02;
}

.description {
  color: theme.get-var('text-helper');
  margin-top: $spacing-03;
  padding-bottom: $spacing-03;
  @include body-03;
}
