@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.AIAdmin {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $spacing-06;

    &--container {
      display: flex;
      flex-direction: column;

      .IconTitle {
        margin-bottom: $spacing-03;
      }
    }
  }

  &__tab {
    &.cds--tab-content {
      padding: $spacing-06 0;

      &:focus {
        outline: none;
      }
    }

    &--published {
      .AIAdmin__table {
        thead,
        tbody {
          > tr {
            th,
            td {
              &:first-child {
                padding: 0;
                width: 50px;
              }
            }

            td {
              &:first-child {
                background-color: theme.get-var('background-selected');
              }
            }
          }
        }
      }
    }
  }

  &__card {
    border-radius: $border-radius-01;
    background-color: theme.get-var('background');
    padding: $spacing-06;
    border: 1px solid theme.get-var('border-subtle');
    margin-bottom: $spacing-07;

    &--limited {
      @media (min-width: 1051px) {
        max-width: 70%;
      }
    }

    .cds--data-table-content {
      border-radius: $border-radius-01;
      border: 1px solid theme.get-var('border-subtle');
    }
  }

  &__table {
    tbody {
      > tr {
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }

    &--container {
      &.active-batch {
        .cds--data-table-content {
          border-radius: 0 0 $border-radius-01 $border-radius-01;
        }
      }
    }

    &--actions {
      min-width: 100px;
    }

    &--status {
      .status-tag {
        @include body-02;
        line-height: 16px;
        cursor: default;
        white-space: nowrap;

        &.published {
          background-color: theme.get-var('tag-background-blue');
          color: theme.get-var('tag-color-blue');
        }

        &.draft {
          background-color: theme.get-var('tag-background-gray');
          color: theme.get-var('text-secondary');
        }

        &.testing {
          background-color: theme.get-var('tag-background-yellow');
          color: theme.get-var('tag-color-yellow');
        }
      }
    }

    &--search {
      max-width: 358px;
      margin-bottom: $spacing-06;

      &.cds--toolbar-search-container-persistent {
        height: auto;

        .cds--search-input {
          &:not(:placeholder-shown),
          &:active,
          &:focus,
          &:hover {
            &:not([disabled]) {
              background-color: theme.get-var('background');
            }
          }

          & ~ .cds--search-close {
            $outline-offset: 2px;

            height: calc(100% - $outline-offset * 2);
            top: $outline-offset;
            right: $outline-offset;
            outline: none;

            &:hover {
              outline: none;
              background-color: theme.get-var('field-hover');
            }
          }
        }
      }
    }

    &--order {
      .cds--number {
        .cds--number__input-wrapper {
          border: 0;
          min-width: auto;
          width: 50px;

          input[type="number"] {
            min-width: auto;
            width: 50px;
            padding: 0 $spacing-03;
            text-align: center;
            border-radius: 0;
            transition: all linear 0.2s;

            &:active,
            &:focus {
              border-radius: $border-radius-01;
            }

            &:hover {
              border: 1px solid theme.get-var('border-subtle-selected');
              background-color: white;
              border-radius: $border-radius-01;
            }
          }
        }

        &[data-invalid] {
          .cds--number__input-wrapper {
            input {
              background-color: theme.get-var('background-error');
              border-radius: $border-radius-01;

              &:active,
              &:focus {
                outline-color: theme.get-var('notification-error-border');
              }
            }

            ~ .cds--form-requirement,
            .cds--number__invalid {
              display: none;
            }
          }
        }
      }
    }

    &--batch {
      &__title {
        padding-left: $spacing-05;
        margin-right: auto;
        display: flex;
        gap: $spacing-06;
      }

      &__warning {
        @include body-03;

        display: flex;
        align-items: center;
        gap: $spacing-03;
        color: theme.get-var('notification-error-border');
      }

      &__actions {
        order: 3;
      }

      &.cds--batch-actions {
        border: 1px solid theme.get-var('border-subtle');
        border-bottom: 0;
      }

      .cds {
        &--action-list {
          width: 100%;
        }

        &--batch-summary {
          display: none;
        }

        &--btn {
          &.cds--btn--primary {
            background-color: theme.get-var('table-header-main');
            color: theme.get-var('text-primary');

            &:hover {
              background-color: theme.get-var('table-header-hover');
            }

            &:focus {
              outline: none;
              border: 0;
              box-shadow: none;
            }

            &:disabled {
              color: theme.get-var('text-disabled');
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__go-back {
    display: flex;
    gap: $spacing-03;
    align-items: center;
    margin-bottom: $spacing-06;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;

    .cds--label {
      @include body-02;
    }

    .cds--text-input__field-wrapper {
      @media (min-width: 901px) {
        max-width: 563px;
      }
    }

    .cds--number__input-wrapper,
    .cds--multi-select__wrapper,
    .cds--dropdown__wrapper,
    .cds--form__helper-text {
      max-width: 288px;
    }

    .cds--dropdown__wrapper {
      margin: 0;
    }

    &--status-dropdown {
      .cds--list-box {
        &__field,
        &__menu {
          text-transform: capitalize;
        }
      }
    }

    &--actions {
      display: flex;
      justify-content: flex-end;
      gap: $spacing-03;

      .cds--inline-loading {
        justify-content: flex-end;
      }

      .cds--btn {
        &--danger--tertiary {
          margin-right: auto;
          padding-right: $spacing-04;
          gap: $spacing-02;
        }
      }
    }
  }
}
