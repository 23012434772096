@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/styles/scss/utilities/custom-property' as theme;
@import '../abstracts/variables';
@import '../abstracts/mixins';
@import '../base/colors';

.Optimise {
  .Optimise__header {
    display: flex;
    align-items: center;
    > .heading-06 {
      margin: 0 $spacing-03;
    }
    margin-bottom: $spacing-05;
    .cds--btn.cds--btn--secondary {
      padding: 6px;
    }
  }

  .NewProducts {
    position: relative;

    .NewProducts__cluster-group {
      display: flex;
      flex-wrap: wrap;
      column-gap: $spacing-02;
    }

    .NewProducts__cluster-tag {
      background: none;
      border-radius: $spacing-13;
      border: 1px solid theme.get-var('border-strong');
      padding: 0 0 0 $spacing-03;

      &.cds--tag,
      &.cds--tag:not(:first-child) {
        margin: $spacing-02;
      }

      .cds--tag__label {
        padding-right: $spacing-02;
      }
      .cds--tag__close-icon:hover {
        background-color: $main-gray-hover-10;
      }
    }
    .NewProducts__add-product {
      margin-top: $spacing-05;
    }

    .NewProducts__table-overlay {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(22, 22, 22, 0.1);
      z-index: 1;
      position: absolute;
      height: 100%;
    }
    .NewProducts__table-force-btn {
      &--auto {
        svg {
          fill: theme.get-var('icon-primary');
        }
      }
      &--in {
        svg {
          fill: theme.get-var('icon-primary-bright');
        }
      }
    }
    .NewProducts__cluster-selection {
      border: 1px solid theme.get-var('border-interactive-primary');
      border-radius: $border-radius-01;
      padding: $spacing-05 $spacing-05 0 $spacing-05;
      position: absolute;
      box-shadow: $boxShadow-01;
      left: 0;
      right: 0;
      background: theme.get-var('background');
      z-index: 1;
      &--show {
        animation: fade-up 0.2s;
        visibility: visible;
        opacity: 1;
      }
      &--hidden {
        visibility: hidden;
        .NewProducts__clusters-container {
          height: 0;
        }
      }
    }
    .NewProducts__clusters-container {
      max-height: 300px;
      overflow: auto;
      padding: $spacing-05 0;
      .NewProducts__clusters {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: $spacing-04;
      }
      .NewProducts__cluster {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-03;
        border: 1px solid theme.get-var('border-strong');
        border-radius: $border-radius-01;
        margin-top: 0;
        cursor: pointer;
        @include body-02;
        > .cds--form-item {
          align-items: flex-end;
        }
      }
    }
    .NewProducts__cancel-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: $spacing-03;
      background-color: theme.get-var('background');
    }
    .NewProducts__action-bar {
      margin: 0 -1rem 0 -1rem;
      padding: $spacing-05;
      background-color: theme.get-var('background');
      box-shadow: $boxShadow-02;
      border-bottom-left-radius: $border-radius-01;
      border-bottom-right-radius: $border-radius-01;
      display: flex;
      justify-content: flex-end;
      gap: $spacing-05;
    }

    .NewProducts__action-bar__spacer {
      flex: 1;
    }

    .NewProducts__actions-cell {
      white-space: nowrap;
      display: flex;
      gap: $spacing-02;
    }

    .cds--data-table {
      tbody > tr > td:nth-child(2) {
        word-break: break-word;
      }
    }
  }
  --grid-item--min-width: 200px;

  .ClusterGoals {
    --grid-layout-gap: 24px;
    --grid-column-count: 2;
    --grid-item--min-width: 400px;
    --grid-item--max-width: calc(
      (100% - var(--grid-layout-gap)) / var(--grid-column-count)
    );
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
    position: relative;
    grid-gap: var(--grid-layout-gap);

    .ClusterGoals__cluster-name {
      @include body-emphasis-02;
      margin-bottom: $spacing-05;
    }
    .ClusterGoals__cluster-goal {
      @include body-02;
      display: grid;
      grid-template-columns: 0.2fr 0.8fr 1fr;
      align-items: center;
      grid-gap: $spacing-05;
      margin-bottom: $spacing-05;
      &--product-summary,
      &--cut {
        margin-top: $spacing-06;
        color: theme.get-var('text-secondary');
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        gap: $spacing-03;
        flex-grow: 1;
      }
      .ClusterGoals__cluster-goal-input {
        &--at-max {
          .cds--number__controls {
            .cds--number__control-btn.up-icon {
              cursor: not-allowed;
              background-color: theme.get-var('background-disabled');
              > svg {
                fill: theme.get-var('text-disabled');
              }
            }
          }
        }
      }
    }
  }

  .PlanTargets {
    .number-picker {
      display: flex;
      align-items: center;
      gap: $spacing-07;
      width: 11rem;
    }

    .cds--number {
      &__control-btn {
        &:before,
        &:after {
          content: none;
        }
      }

      .cds--number__input-wrapper {
        width: 100%;
        max-width: initial;
        min-width: initial;

        ~ .cds--form-requirement {
          display: none;
        }
      }

      .cds--number__invalid {
        right: 5.5rem;
      }

      input[type='number'] {
        background-color: transparent;
        height: 30px;
        min-width: 7rem;
        padding-right: 5rem;

        &:invalid {
          padding-right: 7rem;
        }
      }
    }

    .cds--data-table {
      &--sticky-header {
        max-height: calc(100vh - 350px);
        overflow-y: auto;

        &:not(.cds--data-table--xs):not(.cds--data-table--xl):not(.cds--data-table--sm) {
          td:not(.cds--table-column-menu):not(.cds--table-column-checkbox) {
            padding-top: 0;
            align-items: center;

            &.cds--table-expand {
              padding-top: $spacing-03;
            }
          }
        }

        tr.cds--parent-row.cds--expandable-row + tr[data-child-row] {
          td:first-child {
            padding: $spacing-03 $spacing-05 $spacing-05 3.5rem;
          }

          .Planograms {
            .cds--data-table--lg {
              thead,
              tbody {
                display: table-header-group;

                > tr {
                  display: table-row;

                  > th,
                  > td {
                    display: table-cell;
                    padding: 0 $spacing-05;

                    .cds--table-header-label {
                      max-width: unset;
                    }
                  }
                }
              }
            }
          }
        }

        tbody {
          overflow: unset;
        }

        .cds--table-expand {
          flex-shrink: 0;
          height: auto;

          &__svg {
            flex-shrink: 0;
          }
        }
      }

      .cds--tooltip {
        height: 18px;
        cursor: pointer;
      }

      .cds--toggle__label-text {
        display: none;
      }

      tr.cds--parent-row.cds--expandable-row + tr[data-child-row] {
        td:first-child {
          padding: $spacing-03 $spacing-05 $spacing-05 3.5rem;
        }

        .Planograms {
          .cds--data-table--lg tbody > tr > td {
            padding: 0 $spacing-05;
          }
        }
      }

      .cds--expandable-row > td > .cds--child-row-inner-container {
        // Carbon scrollbar bug workaround
        min-height: 0.5px;
      }
    }
  }

  .Planograms {
    .cds--data-table {
      thead,
      tr,
      th {
        background-color: transparent;
      }

      th {
        border: solid var(--cds-border-subtle);
        border-width: 0 0 1px 0;
      }

      td > .flex-cell {
        display: flex;
        align-items: center;
      }
    }
  }
}

@keyframes fade-up {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
