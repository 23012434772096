@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.TermsAndConditions {
  .cds--modal-container {
    max-width: 1050px;
    width: 80%;
    display: flex;
  }
  .cds--tab-content {
    overflow-y: hidden;
    max-height: 530px;
    &:focus {
      outline: none;
    }
  }
  .TermsAndConditions__container {
    padding: $spacing-07;
    display: flex;
    flex-direction: column;
    align-items: center;
    .TermsAndConditions__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid theme.get-var('border-subtle-01');
      padding: 0 $spacing-09 $spacing-04;
      margin-bottom: $spacing-07;
      max-width: 750px;
      text-align: center;
      .TermsAndConditions__header-title {
        @include heading-06;
        color: theme.get-var('text-primary');
      }
      .TermsAndConditions__header-description {
        @include body-03;
        color: theme.get-var('text-secondary');
      }
    }
    .TermsAndConditions__content {
      max-height: 100%;
      overflow-y: auto;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: $spacing-05;
      padding-right: $spacing-05;
      padding-bottom: $spacing-03;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: theme.get-var('field-02');
        border-radius: $border-radius-01;
      }
      &::-webkit-scrollbar-thumb {
        background: theme.get-var('background-primary');
        border-radius: $border-radius-01;
      }
      scrollbar-color: theme.get-var('background-primary')
        theme.get-var('field-02');
      scrollbar-width: auto;
      h4 {
        color: theme.get-var('text-primary');
        @include body-emphasis-02;
      }
      p {
        @include body-02;
        line-height: 24px;
        color: theme.get-var('text-secondary');
      }
      ul {
        list-style-type: disc;
      }
      ol {
        list-style-type: lower-latin;
      }
      ol,
      ul {
        list-style-position: outside;
        padding-left: $spacing-05;
        li {
          @include body-02;
          line-height: 24px;
          color: theme.get-var('text-secondary');
        }
      }
      .TermsAndConditions__accept-terms-header {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: $spacing-03;
      }
      table {
        border: 1px solid #000000;
        td,
        th {
          @include body-02;
          border: 1px solid #000000;
          padding: $spacing-03;
          line-height: 24px;
          color: theme.get-var('text-secondary');
          text-align: start;
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .TermsAndConditions__accept-terms-button {
      background-color: theme.get-var('background-primary');
      &:hover {
        background-color: theme.get-var('background-primary');
      }
      &.has-icon {
        padding: $spacing-03 $spacing-08 $spacing-03 $spacing-05;
      }
    }
    .TermsAndConditions__close-button {
      position: absolute;
      align-self: flex-end;
      right: 16px;
      top: 16px;
    }
  }
}
