@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.GenAIPanel {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;

  &__header {
    padding-bottom: $spacing-07;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s;

    &--description {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing-03;
    }

    &--active {
      position: relative;
      padding-bottom: $spacing-05;
      flex-direction: row;
      gap: $spacing-05;
      background-color: theme.get-var('background');
      margin-bottom: $spacing-06;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: -$spacing-07;
        right: 0;
        border-bottom: 1px solid theme.get-var('border-subtle');
      }

      .GenAIPanel__header {
        &--description {
          align-items: flex-start;
          gap: 0;
        }

        &--title {
          @include heading-03;
          font-weight: $font-weight-06;
        }

        &--text {
          @include heading-04;
        }

        &--image {
          margin: 0;
        }
      }
    }

    &--image {
      $size: 62px;

      color: #ffffff;
      background-color: theme.get-var('background-primary');
      border-radius: $border-radius-01;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $size;
      width: $size;
      position: relative;
      margin: $spacing-07 0 $spacing-06;

      &:after {
        @include body-02;
        content: 'Beta';
        line-height: 1.1;
        background-color: theme.get-var('highlight');
        color: theme.get-var('text-primary');
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: $border-radius-01;
        padding: $spacing-02 $spacing-03;
      }
    }

    &--title {
      font-size: $font-size-05;
      color: theme.get-var('text-button-primary');
    }

    &--text {
      color: theme.get-var('text-button-primary');
      font-size: $font-size-10;
      line-height: $font-size-12;
    }
  }

  &__extra {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-03;
    width: 100%;
  }

  &__button {
    position: relative;
    text-align: left;
    cursor: pointer;
    border-radius: $border-radius-01 $border-radius-01 $border-radius-01 0;
    padding: $spacing-03 $spacing-05;
    font-size: $font-size-05;
    line-height: 24px;
    border: 1px solid theme.get-var('border-subtle');
    color: theme.get-var('text-button-secondary');
    background: theme.get-var('background');

    &:has(.GenAIPanel__button--label) {
      margin-top: $spacing-04;
    }

    > svg {
      color: theme.get-var('text-button-secondary');
    }

    &:hover {
      background: theme.get-var('main-button-tertiary');
      border: 1px solid theme.get-var('border-interactive-primary');

      &:active {
        background: theme.get-var('main-button-tertiary');
        border-color: theme.get-var('border-interactive-primary');
      }
    }

    &:active,
    &:focus {
      box-shadow: none;
      background: theme.get-var('main-button-tertiary');
      border-color: theme.get-var('main-button-primary-active');
    }

    &:disabled {
      cursor: not-allowed;
      border-color: theme.get-var('background-disabled');
      background: theme.get-var('background-disabled');
      color: theme.get-var('text-on-color-disabled');

      > svg {
        color: theme.get-var('text-on-color-disabled');
      }

      &:active,
      &:hover,
      &:focus {
        background: theme.get-var('background-disabled');
        border-color: theme.get-var('background-disabled');
        outline: 0;
      }
    }

    &--label {
      position: absolute;
      top: -$spacing-04;
      right: -$spacing-03;
      background-color: theme.get-var('tag-background-yellow');
      color: theme.get-var('tag-color-yellow');
      border-radius: $border-radius-01;
      padding: $spacing-01 $spacing-03;
      line-height: 1.4;
    }
  }

  &__message {
    @include body-02;

    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    white-space: pre-wrap;
    max-width: 90%;
    line-height: 24px;

    &--content {
      display: flex;
      gap: $spacing-05;
      align-items: flex-start;
    }

    &--text {
      cursor: text;
    }

    &--user {
      background-color: theme.get-var('background-primary');
      border-color: theme.get-var('background-primary');
      color: theme.get-var('text-on-color');
      padding: $spacing-03 $spacing-05;
      margin-left: auto;
      border-radius: $border-radius-02 $border-radius-02 0 $border-radius-02;
    }

    &--full-width {
      max-width: 100%;
    }

    &--ai {
      background-color: theme.get-var('highlight');
      border-color: theme.get-var('highlight');
      color: theme.get-var('text-primary');
      border-radius: $border-radius-02 $border-radius-02 $border-radius-02 0;
      padding: $spacing-03;

      &__loader {
        flex-direction: row;
        gap: $spacing-05;
      }
    }

    &--error {
      border-color: theme.get-var('notification-error-border');
      background-color: theme.get-var('notification-error-background');

      .GenAIPanel__message--text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .error-icon {
          color: theme.get-var('main-button-danger-primary');
          margin-right: $spacing-03;
        }
      }
    }

    &--icon {
      display: flex;
      color: theme.get-var('background');
      background-color: theme.get-var('background-primary');
      padding: $spacing-02;
      border-radius: 4px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-05;
    width: 100%;

    &--button {
      cursor: pointer;
      color: theme.get-var('icon-primary');
      display: inline-flex;
      align-items: center;
      padding: $spacing-03;
      background: none;
      border: 2px solid transparent;
      border-radius: $border-radius-01;
      font-size: $font-size-05;

      &.active {
        color: theme.get-var('icon-primary-light');

        &:focus,
        &:active {
          color: theme.get-var('icon-primary-light');
          border-color: theme.get-var('icon-primary-light');
        }
      }

      &:hover {
        background-color: theme.get-var('main-button-tertiary-hover');
      }

      &:focus,
      &:active {
        color: theme.get-var('icon-primary-light');
        border-color: theme.get-var('main-button-primary');
      }
    }

    &--container {
      display: flex;
      padding-left: $spacing-09;
    }

    &--options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: $spacing-04;
      background-color: theme.get-var('background');
      border-radius: $border-radius-01;
      margin-top: $spacing-02;

      &__close {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        line-height: 1;
        border-radius: $border-radius-01;
        padding: $spacing-02;

        &:hover {
          background-color: theme.get-var('background-hover');
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $spacing-04;
      }

      &__title {
        @include body-02;
        color: theme.get-var('text-secondary');
        display: flex;
        align-items: center;
        gap: $spacing-03;
      }

      &__text {
        @include body-02;
        color: theme.get-var('text-secondary');
        margin: $spacing-05 0;
      }
    }
  }

  &__posts {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding-right: $spacing-05;
    gap: $spacing-03;
    width: 100%;
    align-items: flex-start;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: $spacing-03 $spacing-06;
    margin-top: auto;
    width: 100%;

    &--text {
      @include body-02;
      color: theme.get-var('text-helper');
      text-align: center;
    }
  }
}
