@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;

.TypingLoader {
  $dot-size: $spacing-02;

  display: flex;
  gap: $spacing-02;
  align-items: center;
  width: 100%;
  padding-top: calc($dot-size / 2);

  &__dot {
    animation: dot-keyframes 1s infinite ease-in-out;
    background-color: theme.get-var('background-primary');
    border-radius: 50%;
    display: inline-block;
    height: $dot-size;
    width: $dot-size;

    &:nth-child(2) {
      animation-delay: .33s;
    }

    &:nth-child(3) {
      animation-delay: .66s;
    }
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: .3;
  }

  40% {
    opacity: 1;
    transform: translateY(-50%);
  }

  60% {
    opacity: 1;
    transform: translateY(-50%);
  }

  100% {
    opacity: .3;
  }
}
