@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/styles/scss/colors' as *;
@use '@carbon/styles/scss/type';
@import '../abstracts/mixins';
@import '../base/typography';
@import '../abstracts/variables';

$nav-height: 50px;
$sidebar-width: 64px;
.app {
  height: 100%;
  display: grid;
  grid-template-rows: $nav-height 1fr;
  grid-template-columns: $sidebar-width 1fr;
  overflow-x: hidden;
  background-color: theme.get-var('background-platform-main');
  @include body-emphasis-02;
}

.app-nav {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $nav-height;
  grid-area: top-nav;
  justify-content: space-between;
  border-bottom: 1px solid theme.get-var('border-subtle');
  background-color: #fff;
  z-index: 6;

  .app-nav__dropdowns-wrapper {
    display: flex;
  }

  .app-nav__user-info {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    margin-right: $spacing-03;
    .app-nav__user-menu {
      border-radius: $border-radius-01;
      background-repeat: no-repeat;
      background-position: $spacing-02 $spacing-02;
    }
    .app-nav__user-banners {
      margin: 0 $spacing-03;
      min-width: 160px;
      max-width: fit-content;
    }
    .app-nav__preview-description {
      padding: $spacing-05;
    }
  }
}

.app-content-container {
  margin-top: $nav-height;
  padding-top: $spacing-07;
  margin-bottom: $spacing-05;
  max-width: unset;
}

.breadcrumb-container {
  margin-bottom: $spacing-03;

  .cds--breadcrumb-item {
    &:not(.cds--breadcrumb-item--current) {
      .cds--link {
        color: theme.get-var('text-secondary');
      }
    }
  }
}

.hoverable:hover {
  transition: background-color 0.1s;
  background-color: theme.get-var('background-hover');
  border-radius: $border-radius-01;
}

.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
  &.justify {
    &-center {
      justify-content: center;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-flex-end {
      justify-content: flex-end;
    }
  }
  &.align-center {
    align-items: center;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.direction-column {
    flex-direction: column;
  }
  &-spacer {
    flex-grow: 1;
  }
}

.gap {
  &--small {
    gap: $spacing-03;
  }
  &--medium {
    gap: $spacing-05;
  }
  &--large {
    gap: $spacing-07;
  }
}

.clickable:active {
  background-color: theme.get-var('layer-accent-active-01');
}

.app-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  border: 2px solid theme.get-var('notification-success-border');
}

.info {
  border: 2px solid theme.get-var('notification-info-border');
}

.warning {
  border: 2px solid theme.get-var('notification-warning-border');
}

.error {
  border: 2px solid theme.get-var('notification-error-border');
}
